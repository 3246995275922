<template>
  <b-tab title="API response" active>
    <template
      v-if="
        apiResponse &&
        (apiResponse.externalAPIResponse || apiResponse.externalAPIError)
      "
    >
      <template v-if="apiResponse && apiResponse.externalAPIError">
        <pre class="mb-0 pl-2 custom-scroll text-danger">{{
          apiResponse.externalAPIError
        }}</pre>
      </template>
      <template v-if="apiResponse && apiResponse.externalAPIResponse">
        <pre class="mb-0 pl-2 custom-scroll">{{
          apiResponse.externalAPIResponse
        }}</pre>
      </template>
    </template>
    <p v-else class="pl-2 color-black">No data available.</p>
  </b-tab>
</template>
<script>
export default {
  name: 'APIResponse',
  props: ['apiResponse']
}
</script>
